<template>
  <a-modal
    width="620px"
    :dialog-style="{ display: 'none' }"
    :body-style="{ height: '760px', minWidth: '620px', display: 'flex', flexDirection: 'column', padding:'29px 40px' }"
    :footer="null"
    :visible="visible"
    @cancel="onCancel">
    <div class="upload-template-description">
      <h1 class="txt-font-din-bold txt-32">{{ $t('components.labels.uploadTranslationTemplate') }}</h1>
      <p class="txt-16">
        {{ $t('components.description.afterFillingAllFieldsForEveryLanguage') }}
        <ul class="txt-font-din-bold txt-black txt-16 language-list">
          <li v-for="lang in existingLanguages" :key="lang">{{ lang }}</li>
        </ul>
      </p>
    </div>
    <a-upload
      accept=".xlsx,
       application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      :file-list="fileList"
      list-type="picture"
      class="w100 upload-template"
      :multiple="false"
      :custom-request="customRequest"
      @change="onChange">
      <div class="upload-translation-image txt-font-din-bold">
        <img src="/calendar-upload.svg" class="mb-24 w25" alt="upload icon">
        <p class="txt-24">{{ $t('components.description.uploadFileXls') }}</p>
      </div>
      <div class="footer">
        <a-button type="primary" :disabled="fileList.length == 0" class="txt-font-din-med footer-btn" @click.stop="onComplete(fileList[0])">
          {{ $t('components.labels.uploadFile', { number: '' }) }}
        </a-button>
      </div>
    </a-upload>
  </a-modal>
</template>
<script>
export default {
  name: 'Upload',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    onComplete: {
      type: Function,
      default: () => {}
    },
    onClose: {
      type: Function,
      default: () => {}
    },
    existingLanguages: {
      type: Array,
      default: () => ['English']
    }
  },
  data() {
    return {
      fileList: []
    };
  },
  computed: {
  },
  methods: {
    onChange(info) {
      this.fileList = [...info.fileList];
    },
    onCancel() {
      this.fileList = [];
      this.onClose();
    },
    customRequest(options) {
      options.onSuccess(null);
    }
  }
};
</script>
<style scoped>
.upload-template-description {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 40%;
  min-height: 240px;
}
.language-list {
  padding-left: 20px;
  line-height: 1.63;
}
.footer {
  text-align: right;
}
.footer-btn {
  min-width: 300px;
  height: 48px;
  width: 40%;
  font-size: 16px;
  border-radius: 1px;
}

.upload-translation-image {
  border: dashed 2px #bebebe;
  color: #363534;
  opacity: 0.23;
  letter-spacing: 0.2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 230px;
  min-width: 542px;
  width: 100%;
}

.upload-template {
  height: 50%;
}
.upload-template >>> .ant-upload {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.upload-template >>> .ant-upload-list-item-progress {
  display: none !important;
}

</style>
