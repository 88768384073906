<template>
  <Upload v-if="visible"
    :visible="visible"
    :existing-languages="languages"
    :on-complete="uploadFile"
    :on-close="onUploadClose"/>
  <Progress
   v-else-if="progress"
   :visible="progress"
   :is-uploaded="response"
   :on-close="onProgessClose"/>
  <Error v-else-if="error"
    :visible="error"
    :error="uploadError"
    :on-close="onErrorClose"/>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import langs from '@/util/languages';
import Upload from './upload.vue';

export default {
  name: 'UploadModal',
  components: {
    Upload,
    Progress: () => import('./progress.vue'),
    Error: () => import('./Errors/errors.vue')
  },
  props: {
    existingLanguages: {
      type: Array,
      default: () => ['English']
    },
    uploadVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      progress: false,
      error: false,
      response: null
    };
  },
  computed: {
    ...mapState({
      surveyId: state => state.survey.surveyId,
      projectId: state => state.survey.project.id,
      activeLanguages: state => state.indicators.activeLanguages,
      uploadError: state => state.indicators.error
    }),
    languages() {
      const activeLanguages = this.activeLanguages.map((l) => (l.language));
      const translatedLanguages = langs.call(this);
      return activeLanguages.map((language) => {
        const knownLanguage = translatedLanguages.find(({ value }) => (language === value));
        if (knownLanguage) return knownLanguage.label;
        return language;
      });
    }
  },
  watch: {
    uploadVisible(newVal, oldVal) {
      if (newVal && !oldVal) {
        this.visible = true;
      }
    }
  },
  methods: {
    ...mapActions(['uploadQuestionnaireTemplate', 'cancelQuestionnaireTemplateUpload', 'getActiveLanguages', 'setFormBuilderAsChanged']),
    async uploadFile(file) {
      this.visible = false;
      this.progress = true;
      this.response = await this.uploadQuestionnaireTemplate({
        surveyId: this.surveyId,
        projectId: this.projectId,
        file: file.originFileObj
      });
      if (!this.response) {
        this.progress = false;
        if (this.uploadError && this.uploadError.response && this.uploadError.response.status === 409) {
          this.error = true;
        } else {
          this.$notification.error({
            message: this.$t('values.error'),
            description: this.uploadError.response.data.message
          });
          this.onProgessClose();
        }
      } else {
        this.setFormBuilderAsChanged();
        this.onProgessClose();
        this.$notification.success({
          message: `${this.$t('values.success')}!`,
          description:
            this.$t('components.description.formSavedSentToSmartCollectSuccessfully')
        });
      }
    },
    onProgessClose() {
      if (!this.response) {
        this.cancelQuestionnaireTemplateUpload();
      } else {
        this.getActiveLanguages(this.surveyId);
      }
      this.progress = false;
      this.$emit('on-close');
    },
    onErrorClose() {
      this.error = false;
      this.$emit('on-close');
    },
    onUploadClose() {
      this.visible = false;
      this.$emit('on-close');
    }
  }
};
</script>

<style>

</style>
